<template>
    <v-autocomplete
        v-model="address"
        :items="offers"
        :loading="isLoading"
        :search-input.sync="search"
        class="main_color_text rounded-0 pa-0 z-index-160"
        :class="isMobile ? 'enter_work_address_mob ma-0' : 'enter_work_address mr-0 mt-0'"
        :placeholder="$t('headers.enter_work_address')"
        height="62"
        append-icon=""
        item-text="label"
        item-value="raw"
        :menu-props="menuConfig"
        no-filter
        hide-no-data
        flat
        hide-details
        outlined
        return-object
        @change="handleChangeAddress"
        @keydown.enter="getSearchResults"
    >
        <template v-if="isMobile" #append>
            <v-btn
                :disabled="submitDisabled"
                depressed
                class="btn_arrow_right pl-0"
                @click="$emit('create-route')"
            >
                <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
        </template>
    </v-autocomplete>
</template>

<script>
import { geoPointFormat } from '@/utils'
import { OpenStreetMapProvider } from 'leaflet-geosearch'

export default {
    props: {
        submitDisabled: {
            type: Boolean,
            default: false
        }
    },

    data: () => ({
        address: {},
        offers: [],
        search: '',
        isLoading: false,
        openStreetProvider: null,
        debounce: null,
        menuConfig: {
            closeOnContentClick: true,
            closeOnClick: true,
        }
    }),

    watch: {
        search (val) {
            val && val !== this.address.label && this.handleSearch(val)
        },
    },

    computed: {
        isMobile() {
            return this.$vuetify.breakpoint.width < 900
        }
    },

    mounted() {
        this.openStreetProvider = new OpenStreetMapProvider()
    },

    methods: {
        async getSearchResults() {
            this.isLoading = true
            this.offers = []

            await this.openStreetProvider
                .search({ query: this.search })
                .then(offers => (this.offers = offers))
                .catch(err => { console.log(err) })
                .finally(() => (this.isLoading = false))
        },
        handleSearch(val) {
            if (this.isLoading || val.length < 3) return

            clearTimeout(this.debounce)

            this.debounce = setTimeout(() => this.getSearchResults(), 600)
        },
        handleChangeAddress() {
            this.$emit('updated', {
                latitude: geoPointFormat(this.address.raw.lat),
                longitude: geoPointFormat(this.address.raw.lon),
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.z-index-160 {
    z-index: 160;
}

.btn_arrow_right {
    background: white !important;
    padding: 0 !important;
    margin: 0 !important;
    width: 62px !important;
    height: 62px !important;
    min-width: 62px !important;
    min-height: 62px !important;
    border-radius: 0 !important;
}

::v-deep .v-input__append-inner {
    padding-left: 0 !important;
}

::v-deep .v-input__control div.v-input__slot  {
    padding-right: 0 !important;
}

::v-deep .v-input__slot fieldset {
    display: none;
}
</style>
